import React from "react";
import styled from "styled-components";
import { breakpoints, colors, eases } from "../styles/variables";
import ImageDivider from "./ImageDivider";

const SectionTitle = styled.div`
  grid-column: 3 / span 8;
  grid-row: 1;
  max-width: 100%;
  text-align: center;
  margin-top: 72px;
  margin-bottom: 32px;

  .text-header {
    font-size: 48px;
    color: white;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .text-miniheader {
    margin-bottom: 38px;
    text-align: center;
    color: white;
  }

  &.theme-dark {
    .text-header {
      color: ${colors.petricite};
    }
    .text-miniheader {
      color: ${colors.petricite};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    margin-top: 36px;

    .text-header {
      font-size: 28px;
    }

    svg,
    .text-header,
    .text-miniheader {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
    margin-bottom: 16px;
    margin-top: 24px;

    svg {
      width: 50px;
      height: 50px;
      margin-bottom: 8px;
    }
    .text-miniheader {
      margin-bottom: 8px;
    }
  }
`;

interface Props {
  colorTheme: "light" | "dark" | "white";
  title: string;
  hideImageDivider?: boolean;
}

export default class ButtonText extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const colorClass = `theme-${this.props.colorTheme}`;

    return (
      <SectionTitle className={colorClass}>
        {!this.props.hideImageDivider && <ImageDivider />}
        <div className="text-header">{this.props.title}</div>
        {this.props.children && <div className="text-miniheader">{this.props.children}</div>}
      </SectionTitle>
    );
  }
}
